import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// Initialize Firebase
const config = {
	apiKey: "AIzaSyBfpKTGyuhRHjHoAq8VdH8UsLA0YE0QcRI",
	authDomain: "global-insurance-llc.firebaseapp.com",
	databaseURL: "https://global-insurance-llc.firebaseio.com",
	projectId: "global-insurance-llc",
	storageBucket: "global-insurance-llc.appspot.com",
	messagingSenderId: "798948350004",
	appId: "1:798948350004:web:e9eb52f1291c1fba",
};

firebase.initializeApp(config);
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();
const fieldValue = firebase.firestore.FieldValue;

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

// Ignore undefined properties
firebase.firestore().settings({
	ignoreUndefinedProperties: true,
});

export { auth, firestore, storage, functions, fieldValue, googleAuthProvider, githubAuthProvider, facebookAuthProvider, twitterAuthProvider };
