import React from "react";
import {Button, Form, Input, message, Icon} from "antd";
import {connect} from "react-redux";
// import {Link} from "react-router-dom";

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage} = this.props;

    return (
      <div className="gx-app-login-wrap">
		<img src={require("./../assets/images/login.jpg")} className={"backgroundFile"} alt='Aguila'/>
		<div className={"backgroundFile"} style={{backgroundColor: "#1B202AF8"}} />
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
			  <img alt="example" src={require("./../assets/images/logo-white.png")} width={350}/>
			  <br/>
			  <Form onSubmit={this.handleSubmit} className="gx-signin-for">
				  <FormItem>
					  {getFieldDecorator("email", {
						  initialValue: "",
						  rules: [{
							  required: true, type: "email", message: "The input is not valid E-mail!",
						  }],
					  })(
						  <Input placeholder="Email" style={{width: 300}}
						  	suffix={<Icon type="user" style={{ color: "#888888" }} />}
						  />
					  )}
				  </FormItem>
				  <FormItem>
					  {getFieldDecorator("password", {
						  initialValue: "",
						  rules: [{required: true, message: "Please input your Password!"}],
					  })(
						  <Input type="password" placeholder="Password" style={{width: 300}}
						  	suffix={<Icon type="key" style={{ color: "#888888" }} />}
						  />
					  )}
				  </FormItem>
				  <br/>
				  <FormItem>
					  <Button className="gx-mb-0" htmlType="submit" style={{
					  	width: 300,borderRadius:100,backgroundColor:"#000",borderColor:"#000",color:"#fff",
						  height: 50
					  }}>
						  <IntlMessages id="app.userAuth.signIn"/>
					  </Button>
				  </FormItem>
			  </Form>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(WrappedNormalLoginForm);
